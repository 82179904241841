import _get from 'lodash/get'
import { vendorLicense } from '@/services/Vendors/VendorLicenses/store'
import { vendorList } from '@/services/Vendors/store'
import { parseDate } from '@/utilities/Date/parse'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      var dateFormat = 'LL'

      const type = _get(entry, 'type', '')
      const code = _get(entry, 'code', '')
      const licenseNumber = _get(entry, 'licenseNumber', '')
      const state = _get(entry, 'stateAbbreviation', '')
      const inactive = _get(entry, 'isInactive', false)
      const isVerified = _get(entry, 'isVerified', false)
      const expirationDate = parseDate(_get(entry, 'expirationDate', null), dateFormat)

      return {
        type: type,
        code: code,
        licenseNumber: licenseNumber,
        state: state,
        active: !inactive,
        isVerified: isVerified,
        expirationDate: expirationDate
      }
    })

    this.rows = rows
  },

  async getVendorGlobalID() {
    await vendorList
      .dispatch('getVendorById', {
        vendorID: this.vendorID
      })
      .then(({ result }) => {
        if (result) {
          this.vendorGlobalID = result.vendorGlobalID
          if (this.isDebug == true) console.debug('getVendorGlobalID=' + this.vendorGlobalID)
        }
      })
  },

  async loadVendorLicenses() {
    if (this.isDebug == true) console.debug('in loadVendorLicenses()...')

    await this.getVendorGlobalID()

    await vendorLicense
      .dispatch('getVendorLicenseList', {
        vendorGlobalID: this.vendorGlobalID
      })
      .then(({ list }) => {
        this.determineRows(list)
      })
  },

  reload() {
    this.loading = true

    this.vendorID = this.$route.params.id || 0

    this.rows = []
    this.loadVendorLicenses()

    this.loading = false
  }
}
