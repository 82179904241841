import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  vendorID: 0,
  vendorGlobalID: 0,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'type',
      label: 'Type',
      aria: 'Type',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'code',
      label: 'Code',
      aria: 'Code',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'licenseNumber',
      label: 'License #',
      aria: 'License #',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'state',
      label: 'State',
      aria: 'State',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'active',
      label: 'Active',
      aria: 'Active',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'isVerified',
      label: 'Verified',
      aria: 'Verified',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'expirationDate',
      label: 'ExpirationDate',
      aria: 'ExpirationDate',
      width: '15%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
